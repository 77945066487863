import axios from 'axios'

const processFOInput = async data => {
    const instance = axios.create({
        baseURL: window.location.href.includes('localhost')
            ? 'http://localhost:8000'
            : 'https://mcs.satellites.smartian.space'
    })
    instance.defaults.headers.common.Authorization
    = 'Token 3ed93772db67233e73b9442d8f27b779f4101325'

    delete data.showLoader
    delete data.showError
    delete data.showInput
    delete data.showOutput

    console.log(data)

    for (const [key, value] of Object.entries(data)) {
        console.log(key, value)
        if (typeof value === 'string' && key !== 'name') {
            console.log(key, value)
            data[key] = Number(value)
        }
    }

    console.log(data)

    const response = await instance
        .post('/fo/process', data)
        .then(res => res)
        .catch(err => err)

    return response
}

export default processFOInput
