import React, { useState } from 'react';

// Dependencies
import { Switch, Route } from 'react-router-dom';

// Views
import Landing from './views/Landing/Landing';
import FOInput from './views/FO/FOInput';
import HSInput from './views/HS/HSInput';
import SignUp from './views/SignUp/SignUp';
import Login from './views/Login/Login';
import Logout from './views/Logout/Logout';

// Components
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

const App = () => {
  const [auth, setAuth] = useState(false);

  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route path="/fo" component={FOInput} />
        <Route path="/hs" component={HSInput} />
        {/* <Route
          path="/signup"
          render={(props) => <SignUp {...props} setAuth={setAuth} />}
        />
        <Route
          path="/login"
          render={(props) => <Login {...props} setAuth={setAuth} />}
        />
        <Route
          path="/logout"
          render={(props) => <Logout {...props} setAuth={setAuth} />}
        />
        {auth ? (
          <>
            <Route path="/fo" component={FOInput} />
            <Route path="/hs" component={HSInput} />
          </>
        ) : null} */}
      </Switch>
      <Footer />
    </>
  );
};

export default App;
