import React from 'react';

// Dependencies
import { Link } from 'react-router-dom';

// Stylesheets
import './Navbar.scss';

// Assets
import title from '../../assets/images/title-white.png';

const Navbar = (props) => {
  return (
    <div className="Navbar">
      <nav className="navbar">
        <Link className="navbar-brand" to="/">
          <img className="navbar-logo" src={title} alt="SmartRecruiters" />
        </Link>
        <div className="navbar-menu">
          <div className="navbar-start">
            {/* {props.auth || sessionStorage.getItem('satellite-mcs-token') ? (
              <Link className="navbar-item is-caps" to="/fo">
                BCGv2
              </Link>
            ) : (
              ''
            )} */}
          </div>
          <div className="navbar-end">
            {/* {props.auth || sessionStorage.getItem('satellite-mcs-token') ? (
              <Link className="navbar-item is-caps" to="/logout">
                LOGOUT
              </Link>
            ) : (
              <>
                <Link className="navbar-item is-caps" to="/login">
                  LOG IN
                </Link>
                <Link className="navbar-item is-caps" to="/signup">
                  SIGN UP
                </Link>
              </>
            )} */}
            <Link className="navbar-item is-caps" to="/hs">
              BCGv1
            </Link>
            <Link className="navbar-item is-caps" to="/fo">
              BCGv2
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
