import React from 'react';

// Dependencies
import { Redirect } from 'react-router-dom';

// Functions
import login from '../../functions/login';

// Components
import Button from '../../components/Button/Button';
import Card from '../../components/Card/Card';
import Loader from '../../components/Loader/Loader';
import Notification from '../../components/Notification/Notification';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      showError: false,
      showForm: true,
      success: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.issueLogin = this.issueLogin.bind(this);
  }

  onChange(event) {
    let newState = {};
    newState[event.target.name] = event.target.value;
    this.setState(newState);
  }

  onSubmit(event) {
    this.setState({ showLanding: false, showLoader: true });

    this.issueLogin();

    event.preventDefault();
    event.stopPropagation();
  }

  async issueLogin() {
    let response = await login(
      this.state.email,
      this.state.password,
      this.state.confirmation
    );

    if (response.status === 200) {
      sessionStorage.setItem('satellite-mcs-token', response.data.token);
      this.props.setAuth(true);
      this.setState({ showLoader: false, success: true });
    } else if (response.status === 401) {
      this.setState({
        showLoader: false,
        error: 'Incorrect password, please reload and try again.',
        showError: true,
      });
    } else {
      this.setState({
        showLoader: false,
        error: response.message,
        showError: true,
      });
    }
  }

  render() {
    if (this.state.showLoader) {
      return <Loader />;
    } else if (this.state.showError) {
      return (
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-half">
                <Notification className="is-danger">
                  <p>
                    Oops - something didn't work! You can reload the page and
                    try again, or if the problem persists, please contact
                    support@smartian.space.
                  </p>
                  <p>
                    <small>{this.state.error}</small>
                  </p>
                </Notification>
              </div>
            </div>
          </div>
        </section>
      );
    } else if (this.state.success) {
      return <Redirect to="/" />;
    } else if (this.state.showForm) {
      return (
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-half">
                <h1 className="title is-3">
                  Log in with your SmartRecruiters email address to access the
                  MCS.
                </h1>
                <form className="form" onSubmit={this.onSubmit}>
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control has-icons-left">
                      <input
                        type="email"
                        name="email"
                        className="input"
                        onChange={this.onChange}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-at"></i>
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Password</label>
                    <div className="control has-icons-left">
                      <input
                        type="password"
                        name="password"
                        className="input"
                        onChange={this.onChange}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-key"></i>
                      </span>
                    </div>
                  </div>
                  <br />
                  <div className="field">
                    <div className="control has-text-centered">
                      <Button
                        className="button is-standard is-caps"
                        text="CONTINUE"
                      />
                      <br />
                      <br />
                      <small>
                        <a href="https://csp.satellites.smartian.space/auth/password_reset/">
                          Forgot your password?
                        </a>
                      </small>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}

export default Login;
