import React from 'react';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/Card';
import Hero from '../../components/Hero/Hero';
import Notification from '../../components/Notification/Notification';
import Loader from '../../components/Loader/Loader';
import processFOInput from '../../functions/processFOInput';

class FOInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      showError: false,
      showInput: true,
      showOutput: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange = (event) => {
    let newState = {};
    newState[event.target.name] = event.target.value;

    this.setState(newState);
  };

  onSubmit = (event) => {
    this.setState({ showInput: false, showLoader: true });

    this.issueProcessInput();

    event.preventDefault();
  };

  async issueProcessInput() {
    let response = await processFOInput(this.state);

    if (response.status === 200) {
      this.setState(this.baseState);
      this.setState({
        deck_link: response.data.deck_link,
      });
      this.setState({ showLoader: false, showOutput: true });
    } else {
      this.setState({
        showLoader: false,
        error: response.message,
        showError: true,
      });
    }
  }

  render() {
    if (this.state.showLoader) {
      return <Loader />;
    } else if (this.state.showError) {
      return (
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-half">
                <Notification className="is-danger">
                  <p>
                    Oops - something didn't work! You can reload the page and
                    try again, or if the problem persists, please contact
                    support@smartian.space.
                  </p>
                  <p>
                    <small>{this.state.error}</small>
                  </p>
                </Notification>
              </div>
            </div>
          </div>
        </section>
      );
    } else if (this.state.showOutput) {
      return (
        <div className="Output">
          <Hero
            title="Business Case Generator (v2)"
            subtitle="Focus on Financial Offer, developed for the Upgrade Program"
          />
          <section className="section">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-one-third">
                  <a
                    href={this.state.deck_link}
                    className="button is-fullwidth is-caps"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    OPEN DECK
                  </a>
                  <br />
                  <a href="/fo" className="button is-fullwidth is-caps">
                    NEW DECK
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    } else if (this.state.showInput) {
      return (
        <div className="Input">
          <Hero
            title="Business Case Generator (v2)"
            subtitle="Focus on Financial Offer, developed for the Upgrade Program"
          />
          <section className="section">
            <div id="input-container" className="container">
              <form className="form" onSubmit={this.onSubmit}>
                <div className="columns is-centered">
                  <div className="column is-half">
                    <div className="field">
                      <label className="label">Name</label>
                      <div className="control has-icons-left">
                        <input
                          name="name"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          required
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="columns is-centered">
                  <div className="column is-3">
                    <h2 className="subtitle is-decour">Technology Costs</h2>
                    <div className="field">
                      <label className="label">ATS</label>
                      <div className="control">
                        <input
                          name="ats_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">CRM</label>
                      <div className="control">
                        <input
                          name="crm_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">AI/ChatBot</label>
                      <div className="control">
                        <input
                          name="ai_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Job Distribution</label>
                      <div className="control">
                        <input
                          name="job_distribution_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Recruitment Marketing</label>
                      <div className="control">
                        <input
                          name="recruitment_mktg_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Texting</label>
                      <div className="control">
                        <input
                          name="texting_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">
                        Integration/Technical Services
                      </label>
                      <div className="control">
                        <input
                          name="technical_services_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">System Administration</label>
                      <div className="control">
                        <input
                          name="admin_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column is-1"></div>
                  <div className="column is-3">
                    <h2 className="subtitle is-decour">Sourcing Costs</h2>
                    <div className="field">
                      <label className="label">Job Advertising</label>
                      <div className="control">
                        <input
                          name="ad_budget_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Agency Retainer</label>
                      <div className="control">
                        <input
                          name="retainer_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Direct Sourcing</label>
                      <div className="control">
                        <input
                          name="subscriptions_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                    <br />
                    <h2 className="subtitle is-decour">People Costs</h2>
                    <div className="field">
                      <label className="label">Annual Recruiter Salary</label>
                      <div className="control">
                        <input
                          name="salary"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">
                        Resume Screening
                        <small>
                          <i>(Amount of applications received per year)</i>
                        </small>
                      </label>
                      <div className="control">
                        <input
                          name="ai_screening_time_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">
                        Team Productivity
                        <small>
                          <i>
                            (# of recruiters & hiring managers active in hiring
                            annually)
                          </i>
                        </small>
                      </label>
                      <div className="control">
                        <input
                          name="team_response_time_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">
                        Interview Scheduling Time
                        <small>
                          <i>(# of recruiters & coordinators)</i>
                        </small>
                      </label>
                      <div className="control">
                        <input
                          name="interview_scheduling_time_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          placeholder="# of recruiters & coordinators"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column is-1"></div>
                  <div className="column is-3">
                    <h2 className="subtitle is-decour">Cost Avoidance</h2>
                    <div className="field">
                      <label className="label">Internal Mobility Module</label>
                      <div className="control">
                        <input
                          name="im_module_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Flexibility</label>
                      <div className="control">
                        <input
                          name="retainer_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          placeholder="Ad-hoc change fees"
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Compliance</label>
                      <div className="control">
                        <input
                          name="fines_cr"
                          type="text"
                          className="input"
                          onChange={this.onChange}
                          placeholder="Fines for non-compliance with data privacy laws"
                          required
                        />
                      </div>
                    </div>
                    <br />
                    <div className="field">
                      <div className="control">
                        <Button
                          className="button is-standard is-caps"
                          text="SUBMIT"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      );
    }
  }
}

export default FOInput;
