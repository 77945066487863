import axios from 'axios'

const login = async (email, password) => {
    const instance = axios.create({
        baseURL: window.location.href.includes('localhost')
            ? 'http://localhost:8000' : 'https://mcs.satellites.smartian.space'
    })
    instance.defaults.validateStatus = status => status >= 200 && status < 500

    const data = {
        username: email,
        password
    }

    const response = await instance
        .post('/auth/login', data)
        .then(res => res)
        .catch(err => err)

    return response
}

export default login
