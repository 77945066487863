import React from 'react';

// Stylesheets
import './Footer.scss';

// Assets
import logo from '../../assets/images/title-color.png';

const Footer = () => {
  return (
    <>
      <div className="footer-filler"></div>
      <footer className="footer">
        <div className="content has-text-centered">
          <p>
            <img className="footer-logo" src={logo} alt="SmartRecruiters" />
          </p>
          <p>
            <small>
              All Rights Reserved |{' '}
              <a
                href="https://www.smartrecruiters.com/legal/general-privacy-policy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>{' '}
              |{' '}
              <a
                href="https://www.smartrecruiters.com/legal/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Terms of Use
              </a>
            </small>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
