import React from 'react';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/Card';
import Hero from '../../components/Hero/Hero';
import Notification from '../../components/Notification/Notification';
import Loader from '../../components/Loader/Loader';
import processHSInput from '../../functions/processHSInput';

class HSInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      showError: false,
      showInput: true,
      showOutput: false,
      tableVisible: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleTable = this.toggleTable.bind(this);
  }

  onChange = (event) => {
    let newState = {};
    newState[event.target.name] = event.target.value;

    this.setState(newState);
  };

  onSubmit = (event) => {
    this.setState({ showInput: false, showLoader: true });

    this.issueProcessInput();

    event.preventDefault();
  };

  toggleTable = (event) => {
    if (this.state.tableVisible === false) {
      this.setState({ tableVisible: true });
    } else {
      this.setState({ tableVisible: false });
    }
  };

  async issueProcessInput() {
    let response = await processHSInput(this.state);

    if (response.status === 201) {
      this.setState(this.baseState);
      console.log(response.data);
      this.setState({
        deck_link: response.data.deck_link,
      });
      this.setState({ showLoader: false, showOutput: true });
    } else {
      this.setState({
        showLoader: false,
        error: response.message,
        showError: true,
      });
    }
  }

  render() {
    if (this.state.showLoader) {
      return <Loader />;
    } else if (this.state.showError) {
      return (
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-half">
                <Notification className="is-danger">
                  <p>
                    Oops - something didn't work! You can reload the page and
                    try again, or if the problem persists, please contact
                    support@smartian.space.
                  </p>
                  <p>
                    <small>{this.state.error}</small>
                  </p>
                </Notification>
              </div>
            </div>
          </div>
        </section>
      );
    } else if (this.state.showOutput) {
      return (
        <div className="Output">
          <Hero
            title="Business Case Generator (v1)"
            subtitle="Focus on Hiring Success"
          />
          <section className="section">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-one-third">
                  <a
                    href={this.state.deck_link}
                    className="button is-fullwidth is-caps"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    OPEN DECK
                  </a>
                  <br />
                  <a href="/hs" className="button is-fullwidth is-caps">
                    NEW DECK
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    } else if (this.state.showInput) {
      return (
        <div className="Input">
          <Hero
            title="Business Case Generator (v1)"
            subtitle="Focus on Hiring Success"
          />
          <section className="section">
            <div id="input-container" className="container">
              <div className="columns is-centered">
                <div className="column is-half">
                  <Notification>
                    Please avoid use of symbols or letters in number inputs. If
                    you are not sure what values to put for some of these
                    inputs, please refer to{' '}
                    <a
                      href="https://docs.google.com/document/d/1pan2W_XxdNzfVx7amYW3PUPu-_ccZKRHttLmJSxcTrk/edit"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b>this document</b>
                    </a>{' '}
                    for some basic benchmarks & assumptions
                  </Notification>
                </div>
              </div>
              <form className="form">
                <div className="columns is-centered">
                  <div className="column is-half">
                    <div className="content">
                      <div className="field">
                        <label className="label">Company Name</label>
                        <div className="control has-icons-left">
                          <input
                            name="name"
                            type="text"
                            className="input"
                            onChange={this.onChange}
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-user"></i>
                          </span>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Annual Revenue</label>
                        <div className="control has-icons-left">
                          <input
                            name="revenue"
                            type="text"
                            className="input"
                            onChange={this.onChange}
                            placeholder="e.g. 2500000000"
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Total Employees</label>
                        <div className="control has-icons-left">
                          <input
                            name="employees"
                            type="text"
                            className="input"
                            onChange={this.onChange}
                            placeholder="e.g. 10000"
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-user"></i>
                          </span>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Total Payroll</label>
                        <div className="control has-icons-left">
                          <input
                            name="payroll"
                            type="text"
                            className="input"
                            onChange={this.onChange}
                            placeholder="e.g. 300000000"
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Annual New Hires</label>
                        <div className="control has-icons-left">
                          <input
                            name="hires"
                            type="text"
                            className="input"
                            onChange={this.onChange}
                            placeholder="e.g. 2500"
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-hashtag"></i>
                          </span>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">
                          Total Annual Recruiting Budget
                          <small>
                            <i>
                              (e.g. job advertising fees, candidate assessment,
                              technology, external recruiting agencies, branding
                              efforts, etc.)
                            </i>
                          </small>
                        </label>
                        <div className="control has-icons-left">
                          <input
                            name="budget"
                            type="text"
                            className="input"
                            onChange={this.onChange}
                            placeholder="e.g. 30000000"
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">
                          % of Jobs Filled on Time
                        </label>
                        <div className="control has-icons-left">
                          <input
                            name="pct_filled"
                            type="text"
                            className="input"
                            onChange={this.onChange}
                            placeholder="e.g. 44"
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-percent"></i>
                          </span>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">
                          Days Between Actual & Targeted Start Date
                        </label>
                        <div className="control has-icons-left">
                          <input
                            name="window"
                            type="text"
                            className="input"
                            onChange={this.onChange}
                            placeholder="e.g. 20"
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-hashtag"></i>
                          </span>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">
                          % of New Hires that are Strong Fits
                        </label>
                        <div className="control has-icons-left">
                          <input
                            name="pct_high"
                            type="text"
                            className="input"
                            onChange={this.onChange}
                            placeholder="e.g. 20"
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-percent"></i>
                          </span>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">
                          % of New Hires that are Poor Fits
                        </label>
                        <div className="control has-icons-left">
                          <input
                            name="pct_low"
                            type="text"
                            className="input"
                            onChange={this.onChange}
                            placeholder="e.g. 20"
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-percent"></i>
                          </span>
                        </div>
                      </div>
                      <br />
                      <div className="buttons">
                        <Button
                          className="button is-standard is-caps is-fullwidth"
                          text="TOGGLE TCO TABLE (Optional)"
                          onClick={this.toggleTable}
                          type="button"
                        />
                        <Button
                          className="button is-standard is-caps is-fullwidth"
                          text="GENERATE PRESENTATION"
                          onClick={this.onSubmit}
                          type="submit"
                        />
                      </div>
                    </div>
                  </div>
                  {this.state.tableVisible ? (
                    <div id="tco-table" className="column is-half">
                      <div className="content">
                        <table className="table is-fullwidth">
                          <thead>
                            <tr>
                              <th>Item</th>
                              <th>SmartRecruiters</th>
                              <th>Current</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>Subscription Fee</th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="sr_sf"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="c_sf"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>ATS</th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    Included
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    Included
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>CRM</th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="sr_crm"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="c_crm"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>AI Candidate Matching</th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="sr_aicm"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="c_aicm"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Programmatic Advertising</th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="sr_pa"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="c_pa"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Marketing</th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="sr_m"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="c_m"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>
                                Other Candidate Sourcing Vendor Integrations
                              </th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="sr_ocsvi"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="c_ocsvi"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Reference Check Integrations</th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="sr_rci"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="c_rci"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Background Screening Integration</th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="sr_bsi"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="c_bsi"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Video Interview Integrations</th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="sr_vii"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="c_vii"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Candidate Assessment Integrations</th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="sr_cai"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="c_cai"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Onboarding Software</th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="sr_os"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="c_os"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>HRIS Integration</th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="sr_hrisi"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="c_hrisi"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Recruitment Strategy Consulting</th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="sr_rsc"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="c_rsc"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Implementation and Integration Set-up</th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="sr_iis"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="c_iis"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Ongoing Integration Maintenace</th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="sr_oim"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="c_oim"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Support</th>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="sr_s"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="field">
                                  <div className="control has-icons-left">
                                    <input
                                      name="c_s"
                                      type="text"
                                      className="input"
                                      onChange={this.onChange}
                                    />
                                    <span className="icon is-small is-left">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : null}
                </div>
              </form>
            </div>
          </section>
        </div>
      );
    }
  }
}

export default HSInput;
