import React from 'react';
import Hero from '../../components/Hero/Hero';

const Landing = () => {
  return (
    <>
      <Hero title="Modular Calculator System" />
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-5">
              <div className="box">
                <h1 className="title is-5">Simplicity for the Complex</h1>
                <div className="content">
                  <p>
                    MCS makes it easy to access internal calculators with
                    uniform UIs and clear purpose descriptions, so there's no
                    confusion.
                  </p>
                </div>
              </div>
            </div>
            <div className="column is-5">
              <div className="box">
                <h1 className="title is-5">Complete Flexibility</h1>
                <div className="content">
                  <p>
                    The architecture of the MCS platform is designed
                    specifically to support fast updates to existing calculators
                    and smooth additions of new calculators.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Landing;
